import queryString from 'query-string'
import { memo, useRef } from 'react'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'

import { ONBOARDING_V2_REDIRECTED_KEY } from '../helpers/constants'
import { useOnboardingChecklist } from '../hooks/query/useOnboardingChecklist'
import { useSkipPermissionCheckFeature } from '../hooks/useHasFeatureFlag'
import { useProductSubscription } from '../hooks/useProductSubscription'
import { getPermissionsConfig } from '../models/permission'
import { hasCompletedOnboarding } from '../pages/Onboarding/OnboardingTaskList.util'
import { currentTeamSelector } from '../redux/selectors/team'
import { currentUserSelector } from '../redux/selectors/users'
import { ApplicationState } from '../redux/store/models'
import {
  ADD_ADMIN_DRIVER_PATH,
  ADD_ADMIN_PATH,
  ADD_DRIVER_PATH,
  ADD_USER_PATH,
  EDIT_CURRENT_USER_ROLE_PATH,
  ONBOARDING_TASK_LIST_PATH,
  PROFILE_PATH,
  SIGN_IN_PATH,
} from '../Routes'

export const PrivateRoute = memo(
  ({
    component,
    ...props
  }: RouteProps & { component: React.ComponentType<RouteComponentProps<any>>; path: string }) => {
    const redirectUrl = props.location ? `${props.location.pathname}${props.location.search}` : ''
    const redirectParam = (queryString.parse(window.location.search).redirectUrl as string) || ''
    const signInPath = `${SIGN_IN_PATH}?redirectUrl=${redirectUrl}`
    const { path } = props

    return (
      <Route
        {...props}
        component={(props: RouteComponentProps<any>) => (
          <RouteNestedComponent
            path={path}
            redirectParam={redirectParam}
            component={component}
            signInPath={signInPath}
            routeProps={props}
          />
        )}
      />
    )
  }
)

interface RouteNestedComponentProps {
  path: string
  redirectParam: string
  component: React.ComponentType<RouteComponentProps<any>>
  signInPath: string
  routeProps: RouteComponentProps<any>
}

function RouteNestedComponent({
  path,
  redirectParam,
  signInPath,
  component: C,
  routeProps,
}: RouteNestedComponentProps) {
  const user = useSelector(currentUserSelector)
  const team = useSelector(currentTeamSelector)
  const { isPremium } = useProductSubscription()
  const authUser = useSelector((state: ApplicationState) => state.auth.isAuthenticated)
  const isApproved = team && team.is_approved
  const userAlreadyBeenRedirectedInSession = useRef(
    localStorage?.getItem?.(ONBOARDING_V2_REDIRECTED_KEY) === '1'
  )
  const { allChecklistItems } = useOnboardingChecklist(user.id, {
    enabled: authUser,
  })
  const isOnboardingCompleted = hasCompletedOnboarding(allChecklistItems, user, team)
  const skipPermissionsCheck = useSkipPermissionCheckFeature()

  const shouldRedirectAllPathsToChecklist =
    !isApproved &&
    !path.startsWith?.('/onboarding/') &&
    !path.startsWith?.(EDIT_CURRENT_USER_ROLE_PATH) &&
    !path.startsWith?.(ADD_USER_PATH) &&
    !path.startsWith?.(ADD_DRIVER_PATH) &&
    !path.startsWith?.(ADD_ADMIN_PATH) &&
    !path.startsWith?.(ADD_ADMIN_DRIVER_PATH) &&
    !path.startsWith?.('/deactivate-user') &&
    !path.startsWith?.('/profile/') &&
    !path.startsWith?.('/cdl-upload/') &&
    !path.startsWith?.('/users/')

  const shouldRedirectInitiallyToChecklist =
    !isOnboardingCompleted &&
    !userAlreadyBeenRedirectedInSession.current &&
    !path.startsWith?.('/onboarding/') &&
    !path.startsWith?.(ADD_DRIVER_PATH)

  if (authUser && redirectParam) {
    return <Redirect to={redirectParam} />
  }

  if (
    authUser &&
    (isPremium || user.is_team_admin) &&
    (shouldRedirectAllPathsToChecklist || shouldRedirectInitiallyToChecklist)
  ) {
    return <Redirect to={ONBOARDING_TASK_LIST_PATH} />
  }

  // Check for current route permissions
  if (!skipPermissionsCheck) {
    for (const [permissionUrl, permissionConfig] of Object.entries(getPermissionsConfig())) {
      if (path.startsWith(permissionUrl) && path !== '/') {
        const userPermission = user.permissions?.[permissionConfig.permission]
        if (!permissionConfig.allow?.includes(path) && !userPermission?.enabled) {
          return <Redirect to={permissionConfig.fallbackUrl || PROFILE_PATH} />
        }
      }
    }
  }

  if (authUser) {
    return <C {...routeProps} />
  }

  // save intended url and redirect to login page
  return <Redirect to={signInPath} />
}
