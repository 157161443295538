import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Text } from 'shared/components/Text/Text'
import { cyanD1, kellyGreen, mangoYellow, midGray, primaryBlack, white } from 'shared/theme/colors'
import { header4 } from 'shared/theme/typography'
import styled, { css } from 'styled-components'

import { useProductSubscription } from '../../../hooks/useProductSubscription'
import { currentTeamSelector } from '../../../redux/selectors/team'
import { currentUserSelector } from '../../../redux/selectors/users'
import { FLEET_PROFILE_PATH } from '../../../Routes'
import { useLayoutContext } from '../../Context/LayoutContext'

export function SideBarHeader() {
  const user = useSelector(currentUserSelector)
  const team = useSelector(currentTeamSelector)
  const { isPremium, isCreditOnly } = useProductSubscription()
  const { isSideBarCollapsed } = useLayoutContext()

  function getAvatarColor() {
    if (isPremium) {
      if (user.is_team_admin) return kellyGreen
      return mangoYellow
    }
    if (isCreditOnly) {
      if (user.is_team_admin) return midGray
      return '#FB4B6B'
    }

    if (user.is_team_admin) return cyanD1
    return '#613E9B'
  }

  return (
    <StyledLink to={FLEET_PROFILE_PATH}>
      <FlexBox alignItems="center" gap="16px">
        <TeamAvatar $background={getAvatarColor()}>{team.name?.trim()?.split('')[0]}</TeamAvatar>
        <CompanyName variant="header3" $isCollapsed={isSideBarCollapsed}>
          {team.name}
        </CompanyName>
      </FlexBox>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  height: 32px;
  max-height: 32px;
`

const TeamAvatar = styled.div<{ $background?: string }>`
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: #01b474;
  border-radius: 3px;
  ${header4}
  color: ${white};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;

  ${(p) =>
    p.$background &&
    css`
      background-color: ${p.$background};
    `}
`

const CompanyName = styled(Text)<{ $isCollapsed: boolean }>`
  font-size: 15px;
  padding-top: 3px;
  color: ${primaryBlack};
  transition: color 0.3s, opacity 0.3s;
  max-height: 37px;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
  line-height: 1.2;

  ${(p) =>
    p.$isCollapsed &&
    css`
      opacity: 0;
    `}
`
