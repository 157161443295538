import CardMembershipIcon from '@material-ui/icons/CardMembership'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { SyntheticEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Grid } from 'shared/components/Base/Base'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Text } from 'shared/components/Text/Text'
import { useBreakpoint } from 'shared/hooks/useBreakpoint'
import { royalBlue } from 'shared/theme/colors'
import styled, { css } from 'styled-components'

import { useAnalyticsEvent } from '../../../hooks/useAnalyticsEvent'
import { useProductSubscription } from '../../../hooks/useProductSubscription'
import { ONBOARDING_TASK_LIST_PATH, PROFILE_SUBSCRIPTION_PATH } from '../../../Routes'
import { useLayoutContext } from '../../Context/LayoutContext'

export function SideBarFooter() {
  const { isPremium, isCreditOnly } = useProductSubscription()
  const { isSideBarCollapsed, setIsSideBarCollapsed } = useLayoutContext()
  const trackEvent = useAnalyticsEvent()
  const isXsMobile = useBreakpoint('xs')
  const history = useHistory()

  if (isCreditOnly) {
    return null
  }

  function handleItemClick(e: SyntheticEvent<HTMLAnchorElement>, event: string, url: string) {
    trackEvent('nav_sidebar_click', { page_name: event })

    if (isXsMobile) {
      e.preventDefault()
      setIsSideBarCollapsed(true)
      // Wait for sidebar to be collapsed for nice animation
      setTimeout(() => history.push(url), 300)
    }
  }

  function renderContent() {
    if (isPremium) {
      return (
        <StyledLink
          to={ONBOARDING_TASK_LIST_PATH}
          onClick={(e) => handleItemClick(e, 'onboarding_tasks', ONBOARDING_TASK_LIST_PATH)}
        >
          <Grid columns="32px auto" alignItems="center" gap="5px">
            <Icon as={PlaylistAddCheckIcon} />
            <StyledText variant="header3" $isCollapsed={isSideBarCollapsed}>
              Onboarding tasks
            </StyledText>
          </Grid>
        </StyledLink>
      )
    }
    return (
      <StyledLink
        to={PROFILE_SUBSCRIPTION_PATH}
        onClick={(e) => handleItemClick(e, 'subscription', PROFILE_SUBSCRIPTION_PATH)}
      >
        <Grid columns="32px auto" alignItems="center" gap="5px">
          <Icon as={CardMembershipIcon} />
          <StyledText variant="header3" $isCollapsed={isSideBarCollapsed}>
            Subscription
          </StyledText>
        </Grid>
      </StyledLink>
    )
  }

  return (
    <FlexBox alignItems="center" gap="16px" mt={1}>
      {renderContent()}
    </FlexBox>
  )
}

const Icon = styled.div`
  color: ${royalBlue};
  margin-left: 4px;
  transition: color 0.3s !important;
`

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
`

const StyledText = styled(Text)<{ $isCollapsed: boolean }>`
  font-size: 15px;
  padding-top: 3px;
  color: ${royalBlue};
  transition: opacity 0.3s;

  ${(p) =>
    p.$isCollapsed &&
    css`
      opacity: 0;
    `}
`
