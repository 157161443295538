import { capitalize, omit } from 'lodash'
import { ChecklistStatusDetailText } from 'shared/helpers/constants'
import { getDocumentsInitialErrors as getTrailerDocumentsInitialErrors } from 'shared/models/trailer'
import { getDocumentsInitialErrors as getTruckDocumentsInitialErrors } from 'shared/models/truck'

import { CreditApplicationStatus } from '../../models/cash'
import {
  BackgroundCheckVendor,
  OnboardingChecklist,
  OnboardingChecklistName,
  OnboardingSubChecklist,
} from '../../models/onboarding'
import { PlaidAccountStatus } from '../../models/plaid'
import { ProductSubscriptionBundleName } from '../../models/productSubscription'
import { SlimTeam } from '../../models/team'
import { User } from '../../models/user'
import { TaskStatusEnum } from '../TaskList/TaskList'

export function parseOnboardingTaskList(
  onboardingChecklist: ReadonlyArray<OnboardingChecklist>,
  user: User,
  team: SlimTeam,
  getCtaText?: (task: OnboardingChecklist | OnboardingSubChecklist) => string,
  getCta?: (
    task: OnboardingChecklist | OnboardingSubChecklist,
    user: User,
    team: SlimTeam
  ) => (() => string | void) | undefined,
  getCtaRenderer?: (
    task: OnboardingChecklist | OnboardingSubChecklist,
    user: User,
    team: SlimTeam
  ) => React.ReactNode,
  getAdditionalText?: (
    task: OnboardingChecklist | OnboardingSubChecklist
  ) => React.ReactNode | React.ReactNode[]
) {
  return onboardingChecklist
    .map((task) => {
      mutateTaskStatus(task, user, team)
      return {
        ...task,
        key: getKey(task),
        name: task.name,
        title: getTitle(task, user),
        status: task.status,
        statusText: ChecklistStatusDetailText[task.statusDetail || 'NONE'] || getStatusText(task, user, team),
        statusAdditionalText: getAdditionalText?.(task),
        subTitle: getSubTitle(task, user),
        ctaText: getCtaText?.(task),
        onCtaClick: getCta?.(task, user, team),
        ctaRenderer: getCtaRenderer?.(task, user, team),
        isExpanded: getIsExpanded(task),
        subTasks: task.subitems?.map((subtask) => {
          mutateTaskStatus(subtask, user, team)

          return {
            key: getKey(subtask),
            name: subtask.name,
            title: getTitle(subtask, user),
            status: subtask.status,
            statusText:
              ChecklistStatusDetailText[subtask.statusDetail || 'NONE'] || getStatusText(subtask, user, team),
            statusAdditionalText: getAdditionalText?.(subtask),
            subTitle: getSubTitle(subtask, user),
            ctaText: getCtaText?.(subtask),
            onCtaClick: getCta?.(subtask, user, team),
            ctaRenderer: getCtaRenderer?.(subtask, user, team),
          }
        }),
      }
    })
    .filter((task) => Boolean(task.title))
}

function getTitle(task: OnboardingChecklist | OnboardingSubChecklist, user: User) {
  switch (task.name) {
    case OnboardingChecklistName.BACKGROUND_CHECK:
      if (task.context?.backgroundCheckVendor) {
        return task.context.backgroundCheckVendor === BackgroundCheckVendor.CHECKR
          ? 'Drug screen'
          : 'Background check and drug screen'
      }
      return user.permissions?.checkrPackage2Onboarding?.enabled
        ? 'Drug screen'
        : 'Background check and drug screen'

    case OnboardingChecklistName.SIGN_AGREEMENT:
      return 'Sign Lease Agreement'

    case OnboardingChecklistName.DRIVER_APPLICATION:
      return 'Driver application'

    case OnboardingChecklistName.EMPLOYMENT_HISTORY:
      return 'Employment history'

    case OnboardingChecklistName.FLEET_APPLICATION:
      return 'Fleet application'

    case OnboardingChecklistName.FLEET_DETAILS:
      return 'Fleet details'

    case OnboardingChecklistName.ELD_SETUP:
      return 'ELD account setup'

    case OnboardingChecklistName.EQUIPMENT_SETUP:
      return 'Add equipment'

    case OnboardingChecklistName.EQUIPMENT_DOCUMENTS:
      return 'Equipment Documents'

    case OnboardingChecklistName.MAINTENANCE_SET_ASIDE:
      return 'Maintenance fund (optional)'

    case OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT:
    case OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT_DOCUMENTS: {
      const equipment = task.context?.truck || task.context?.trailer
      if (!equipment) {
        return 'Equipment'
      }
      return `${equipment.vehicleMake} ${equipment.vehicleModel} ${equipment.vehicleYear}`
    }

    case OnboardingChecklistName.FLEX_AGREEMENT:
      return 'Service & Instant Pay Agreement'

    case OnboardingChecklistName.TAX_INFO:
      return 'W-9 and Taxpayer ID Number'

    case OnboardingChecklistName.ADD_DRIVERS:
      return 'Add driver'

    case OnboardingChecklistName.BROKER_SETUP:
      return 'Broker and load board setup'

    case OnboardingChecklistName.PAYMENT_PROFILE_SETUP:
      return 'CT Cash card'

    case OnboardingChecklistName.BANK_ACCOUNT_INFO:
      return 'Add bank account'

    case OnboardingChecklistName.STRIPE_PAYMENT_PROFILE:
      return 'Verify your identity'

    case OnboardingChecklistName.CREDIT_APPLICATION:
      return 'Apply for credit'

    case OnboardingChecklistName.CONFIRM_CT_CARD_INFO:
      return 'Order card'

    case OnboardingChecklistName.INSURANCE_INFORMATION:
      return 'Occupational accident insurance'

    case OnboardingChecklistName.ADD_INDIVIDUAL_BROKER:
      return capitalize(`${task.context?.provider?.name}`)

    case OnboardingChecklistName.FLEX_STARTER_SUBSCRIPTION:
      return 'Billing and subscription'

    case OnboardingChecklistName.CONTRACTOR_PAYMENTS_PROFILE:
      return 'Create payment profile'

    case OnboardingChecklistName.PAYROLL_BANK_ACCOUNT:
      return 'Add payroll bank account'

    case OnboardingChecklistName.ADD_CONTRACTOR:
      return 'Add contractors'

    case OnboardingChecklistName.CONTRACTOR_PERSONAL_PROFILE:
      return 'Confirm personal info'

    case OnboardingChecklistName.CONTRACTOR_PERSONAL_DIRECT_DEPOSIT:
      return 'Add direct deposit account'

    case OnboardingChecklistName.CONTRACTOR_PERSONAL_DEBIT_CARD:
      return 'Add debit card for instant pay'

    case OnboardingChecklistName.ADD_INDIVIDUAL_DRIVER:
      return `${task.context?.user?.firstName} ${task.context?.user?.lastName}${
        task.context?.user?.id === user.id ? ' (you)' : ''
      }`

    case OnboardingChecklistName.FUEL_CARD:
      return 'CT Fuel card (optional)'
  }
}

function getSubTitle(task: OnboardingChecklist | OnboardingSubChecklist, user: User) {
  switch (task.name) {
    case OnboardingChecklistName.BACKGROUND_CHECK:
      if (task.context?.backgroundCheckVendor) {
        return task.context.backgroundCheckVendor === BackgroundCheckVendor.CHECKR
          ? 'via Checkr'
          : 'via HireRight'
      }
      return user.permissions?.checkrPackage2Onboarding?.enabled ? 'via Checkr' : 'via HireRight'
    case OnboardingChecklistName.CONTRACTOR_PERSONAL_PROFILE:
    case OnboardingChecklistName.CONTRACTOR_PAYMENTS_PROFILE:
      return 'via Astra'

    case OnboardingChecklistName.PAYROLL_BANK_ACCOUNT:
    case OnboardingChecklistName.CONTRACTOR_PERSONAL_DIRECT_DEPOSIT:
      return 'via Plaid'

    case OnboardingChecklistName.STRIPE_PAYMENT_PROFILE:
      return 'via Stripe'

    case OnboardingChecklistName.BANK_ACCOUNT_INFO:
      return 'via Plaid'

    default:
      return undefined
  }
}

function getStatusText(task: OnboardingChecklist | OnboardingSubChecklist, user: User, team: SlimTeam) {
  if (
    (task.name === OnboardingChecklistName.EQUIPMENT_DOCUMENTS ||
      task.name === OnboardingChecklistName.BROKER_SETUP ||
      task.name === OnboardingChecklistName.ADD_DRIVERS ||
      task.name === OnboardingChecklistName.PAYMENT_PROFILE_SETUP ||
      task.name === OnboardingChecklistName.EQUIPMENT_SETUP ||
      task.name === OnboardingChecklistName.MAINTENANCE_SET_ASIDE) &&
    task.status !== TaskStatusEnum.PREREQUISITES_NOT_MET
  ) {
    return ''
  }

  switch (task.status) {
    case TaskStatusEnum.NOT_STARTED: {
      if (task.name === OnboardingChecklistName.FLEX_AGREEMENT) {
        return 'Check your e-mail for a DocuSign link'
      }
      if (task.name === OnboardingChecklistName.SIGN_AGREEMENT) {
        if (user.featureFlags?.publicInAppSignLeaseAgreement) return 'Not started'
        return 'Check your e-mail for a DocuSign link'
      }
      return 'Not started'
    }

    case TaskStatusEnum.IN_PROGRESS: {
      if (task.name === OnboardingChecklistName.ADD_INDIVIDUAL_DRIVER) return 'Onboarding In Progress'
      if (
        task.name === OnboardingChecklistName.SIGN_AGREEMENT &&
        user.featureFlags?.publicInAppSignLeaseAgreement
      )
        return 'In progress'
      if (
        task.name === OnboardingChecklistName.DRIVER_APPLICATION ||
        task.name === OnboardingChecklistName.FLEX_AGREEMENT ||
        task.name === OnboardingChecklistName.SIGN_AGREEMENT ||
        task.name === OnboardingChecklistName.FLEET_DETAILS ||
        task.name === OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT ||
        task.name === OnboardingChecklistName.INSURANCE_INFORMATION ||
        task.name === OnboardingChecklistName.EMPLOYMENT_HISTORY ||
        task.name === OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT_DOCUMENTS
      )
        return 'In review'
      if (task.name === OnboardingChecklistName.CREDIT_APPLICATION) {
        if (team.creditStatus === CreditApplicationStatus.APPROVED)
          return 'Credit extended, pending acceptance'
        return 'In review'
      }
      if (
        task.name === OnboardingChecklistName.ADD_INDIVIDUAL_BROKER ||
        task.name === OnboardingChecklistName.ELD_SETUP
      ) {
        return `Link pending`
      }
      if (task.name === OnboardingChecklistName.BANK_ACCOUNT_INFO) {
        if (
          task.context?.plaidAccount?.verificationStatus === PlaidAccountStatus.PENDING_MANUAL_VERIFICATION
        ) {
          return `Depositing in progress`
        }
        return `Verification in progress`
      }
      if (
        task.name === OnboardingChecklistName.CONFIRM_CT_CARD_INFO ||
        task.name === OnboardingChecklistName.STRIPE_PAYMENT_PROFILE
      ) {
        return `Action required`
      }

      if (task.name === OnboardingChecklistName.BACKGROUND_CHECK) {
        if (task.statusDetail === 'SCREENING_COMPLETED') {
          return 'Screening completed'
        }
        if (task.context?.backgroundCheckVendor === BackgroundCheckVendor.CHECKR) {
          return `Scheduled`
        }
      }

      return 'In progress'
    }

    case TaskStatusEnum.COMPLETED: {
      if (task.name === OnboardingChecklistName.ADD_INDIVIDUAL_DRIVER) return 'Joined'

      if (
        task.name === OnboardingChecklistName.FLEX_AGREEMENT ||
        task.name === OnboardingChecklistName.SIGN_AGREEMENT
      ) {
        if (user.featureFlags?.publicInAppSignLeaseAgreement) return 'Completed'
        return 'Completed on DocuSign'
      }

      return 'Completed'
    }

    case TaskStatusEnum.PREREQUISITES_NOT_MET: {
      if (task.name === OnboardingChecklistName.EMPLOYMENT_HISTORY) {
        return 'Complete driver application'
      }
      if (task.name === OnboardingChecklistName.BACKGROUND_CHECK) {
        return ''
      }
      if (task.name === OnboardingChecklistName.FUEL_CARD) {
        return 'Add bank account'
      }
      if (task.name === OnboardingChecklistName.SIGN_AGREEMENT) {
        if (user.featureFlags?.publicInAppSignLeaseAgreement) {
          if (task.statusDetail === 'WRONG_RECIPIENT_FOR_LEASE_AGREEMENT')
            return 'Contact the fleet owner to sign the lease agreement'
          if (task.statusDetail === 'MISSING_INFORMATION_FOR_LEASE_AGREEMENT') {
            return user.isTeamDriver
              ? 'Complete driver application, fleet details, and add a truck'
              : 'You need to complete fleet details and at least one driver needs to complete driver application and add truck'
          }
          return ''
        }
        return user.isTeamDriver
          ? 'Complete driver application'
          : 'At least one driver needs to complete driver application'
      }
      if (
        task.name === OnboardingChecklistName.EQUIPMENT_SETUP ||
        task.name === OnboardingChecklistName.BROKER_SETUP ||
        task.name === OnboardingChecklistName.PAYMENT_PROFILE_SETUP ||
        task.name === OnboardingChecklistName.TAX_INFO ||
        task.name === OnboardingChecklistName.ADD_DRIVERS
      ) {
        if (task.name === OnboardingChecklistName.PAYMENT_PROFILE_SETUP) {
          if (!team.isTaxInfoComplete) return 'W9 or Tax ID is missing, contact your onboarding specialist'
          return 'Complete sign lease agreement'
        }
        if (team.productSubscriptionName === ProductSubscriptionBundleName.PREMIUM) {
          return 'Complete Sign Lease Agreement'
        }

        if (team.isFlexStarter) {
          return 'Complete Billing and Subscription'
        }

        return 'Complete Service & Instant Pay Agreement'
      }

      if (task.name === OnboardingChecklistName.CREDIT_APPLICATION)
        return 'Add bank account, verify your identity'

      if (task.name === OnboardingChecklistName.CONFIRM_CT_CARD_INFO) {
        if (task.statusDetail === 'NOT_CASH_USER') return 'Contact the first admin on your fleet to order'
        return 'Add bank account, verify your identity'
      }

      if (
        task.name === OnboardingChecklistName.PAYROLL_BANK_ACCOUNT ||
        task.name === OnboardingChecklistName.ADD_CONTRACTOR
      ) {
        return 'Complete payment profile'
      }

      if (
        task.name === OnboardingChecklistName.CONTRACTOR_PERSONAL_DIRECT_DEPOSIT ||
        task.name === OnboardingChecklistName.CONTRACTOR_PERSONAL_DEBIT_CARD
      ) {
        return 'Confirm personal info'
      }

      return 'Not started'
    }

    case TaskStatusEnum.ERROR:
      if (task.name === OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT) {
        return 'Missing information'
      }
      if (task.name === OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT_DOCUMENTS) {
        if (task.context?.truck) {
          const errors = getTruckDocumentsInitialErrors(task.context?.truck).length
          return `Missing information ${errors ? `(${errors})` : ''}`
        }
        if (task.context?.trailer) {
          const errors = getTrailerDocumentsInitialErrors(task.context?.trailer).length
          return `Missing information ${errors ? `(${errors})` : ''}`
        }
        return ''
      }
      if (task.name === OnboardingChecklistName.BANK_ACCOUNT_INFO) {
        return `Account linking error`
      }
      if (task.name === OnboardingChecklistName.DRIVER_APPLICATION) {
        if (task.statusDetail === 'MISSING_BG_CHECK') {
          if (task.context?.backgroundCheckVendor) {
            return task.context.backgroundCheckVendor === BackgroundCheckVendor.CHECKR
              ? `Consent needed`
              : `Complete in HireRight`
          }
          return user.permissions?.checkrPackage2Onboarding?.enabled
            ? `Consent needed`
            : `Complete in HireRight`
        }
        return `Missing information`
      }
      if (
        task.name === OnboardingChecklistName.CREDIT_APPLICATION ||
        task.name === OnboardingChecklistName.EMPLOYMENT_HISTORY
      ) {
        return `Missing information`
      }
      if (task.name === OnboardingChecklistName.FLEET_APPLICATION) {
        if (task.context?.fleetApplication) {
          const fleetApplication = Object.values(
            omit(task.context?.fleetApplication, [
              'businessType',
              team.productSubscriptionName === ProductSubscriptionBundleName.CASH
                ? 'certificateOfInsurance'
                : '',
            ])
          )
          return `${fleetApplication.filter(Boolean).length} of ${fleetApplication.length} Completed`
        }
        return ''
      }
      return ''

    default:
      return ''
  }
}

function getKey(task: OnboardingChecklist | OnboardingSubChecklist) {
  switch (task.name) {
    case OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT: {
      return task?.context?.truck?.id || task?.context?.trailer?.id
    }
    default:
      return ''
  }
}

function getIsExpanded(task: OnboardingChecklist | OnboardingSubChecklist) {
  if (task.name === OnboardingChecklistName.PAYMENT_PROFILE_SETUP) {
    const allSubtasksCompleted = (task as OnboardingChecklist)?.subitems.every(
      (item) => item.status === TaskStatusEnum.COMPLETED
    )

    if (allSubtasksCompleted) return false
    return (task as OnboardingChecklist)?.subitems.some((item) => item.status === TaskStatusEnum.COMPLETED)
  }

  if (task.name === OnboardingChecklistName.EQUIPMENT_SETUP) {
    return (task as OnboardingChecklist).subitems?.length > 0
  }

  return undefined
}

function mutateTaskStatus(task: OnboardingChecklist | OnboardingSubChecklist, user: User, team: SlimTeam) {
  if (task.name === OnboardingChecklistName.PAYMENT_PROFILE_SETUP) {
    const bankAccount = (task as OnboardingChecklist).subitems?.find(
      (item) => item.name === OnboardingChecklistName.BANK_ACCOUNT_INFO
    )
    const paymentProfile = (task as OnboardingChecklist).subitems?.find(
      (item) => item.name === OnboardingChecklistName.STRIPE_PAYMENT_PROFILE
    )
    const ctCash = (task as OnboardingChecklist).subitems?.find(
      (item) => item.name === OnboardingChecklistName.CONFIRM_CT_CARD_INFO
    )
    const creditTask = (task as OnboardingChecklist).subitems?.find(
      (t) => t.name === OnboardingChecklistName.CREDIT_APPLICATION
    )
    const hasCreditTask = Boolean(creditTask)
    const isCreditOnly = team.isCreditOnly

    if (
      bankAccount?.status === TaskStatusEnum.COMPLETED &&
      paymentProfile?.status === TaskStatusEnum.COMPLETED &&
      creditTask?.status === TaskStatusEnum.PREREQUISITES_NOT_MET
    ) {
      creditTask.statusDetail = 'CONTACT_CLOUDTRUCKS_REPRESENTATIVE'
    }

    if (
      hasCreditTask &&
      creditTask?.status !== TaskStatusEnum.COMPLETED &&
      ctCash?.status === TaskStatusEnum.PREREQUISITES_NOT_MET
    ) {
      ctCash.statusDetail = 'COMPLETE_PREVIOUS_TASKS'
    } else if (isCreditOnly && !hasCreditTask && ctCash?.status === TaskStatusEnum.PREREQUISITES_NOT_MET) {
      ctCash.statusDetail = 'FLEET_NOT_ELIGIBLE'
    } else if (
      bankAccount?.status === TaskStatusEnum.COMPLETED &&
      paymentProfile?.status === TaskStatusEnum.COMPLETED &&
      ctCash?.status === TaskStatusEnum.PREREQUISITES_NOT_MET
    ) {
      ctCash.statusDetail = 'CONTACT_CLOUDTRUCKS_REPRESENTATIVE'
    }
  }

  if (task.name === OnboardingChecklistName.PAYMENT_PROFILE_SETUP) {
    const subtasksWithoutStripe = (task as OnboardingChecklist).subitems.filter(
      (t) => t.name !== OnboardingChecklistName.STRIPE_PAYMENT_PROFILE
    )
    const stripeTask = (task as OnboardingChecklist).subitems.find(
      (t) => t.name !== OnboardingChecklistName.STRIPE_PAYMENT_PROFILE
    )
    const subtasksWithoutStripeNotStarted = subtasksWithoutStripe.every(
      (t) => t.status === TaskStatusEnum.PREREQUISITES_NOT_MET || t.status === TaskStatusEnum.NOT_STARTED
    )

    if (
      subtasksWithoutStripeNotStarted &&
      task.status !== TaskStatusEnum.PREREQUISITES_NOT_MET &&
      (stripeTask?.status === TaskStatusEnum.NOT_STARTED || stripeTask?.status === TaskStatusEnum.IN_PROGRESS)
    ) {
      task.status = TaskStatusEnum.NOT_STARTED
    }
  }

  // Payment profile returns by default in progress state (due to STRIPE integration) but we should show not started icon for that
  if (
    (task.name === OnboardingChecklistName.CONFIRM_CT_CARD_INFO ||
      task.name === OnboardingChecklistName.STRIPE_PAYMENT_PROFILE) &&
    task.status === TaskStatusEnum.IN_PROGRESS
  ) {
    task.status = TaskStatusEnum.NOT_STARTED
  }
}
