import { useUserProvider } from '../components/Context/UserContext'

const urls = {
  'ct-credit': {
    intercom: 'https://help.cloudtrucks.com/en/articles/6271144-ct-credit',
    default: 'https://cloudtrucks.zendesk.com/hc/en-us/articles/13975168682519-CT-Credit-FAQs',
    'non-vc': 'https://cloudtrucks-your-authority.zendesk.com/hc/en-us/articles/13946810906391-CT-Credit-FAQ',
  },
  'external-load-booking': {
    // https://help.cloudtrucks.com/en/articles/5632720-external-load-booking#h_c51118ca44
    // https://help.cloudtrucks.com/en/articles/5632720-external-load-booking#h_9cbbeaa8d8
    intercom: 'https://help.cloudtrucks.com/en/articles/5632720-external-load-booking#h_2deea4f2a9',
    default: 'https://cloudtrucks.zendesk.com/hc/en-us/articles/13975494845079',
  },
  'external-load-booking-restrictions': {
    intercom: 'https://help.cloudtrucks.com/en/articles/5632720-external-load-booking#h_2d87d2d922',
    default: 'https://cloudtrucks-your-authority.zendesk.com/hc/en-us/articles/13946378949015',
  },
  'broker-requirements': {
    intercom: 'https://help.cloudtrucks.com/en/articles/5632768-broker-requirements',
    default: 'https://cloudtrucks.zendesk.com/hc/en-us/articles/13976460967447-How-to-add-a-new-broker',
    'non-vc': 'https://cloudtrucks-your-authority.zendesk.com/hc/en-us/articles/14179686400279',
  },
  'insurance-requirements': {
    intercom: 'https://help.cloudtrucks.com/en/articles/5746045-insurance-requirements',
    default:
      'https://cloudtrucks.zendesk.com/hc/en-us/articles/13976031707543-CloudTrucks-insurance-partners',
  },
  'setting-up-broker': {
    intercom: 'https://help.cloudtrucks.com/en/articles/5504152-setting-up-brokers-with-cloudtrucks',
    default:
      'https://cloudtrucks.zendesk.com/hc/en-us/articles/13976783150231-How-to-create-accounts-with-brokers',
    'non-vc': 'https://cloudtrucks-your-authority.zendesk.com/hc/en-us/articles/13946217826455',
  },
  'virtual-card': {
    intercom: 'https://help.cloudtrucks.com/en/articles/5371337-ct-cash-virtual-card',
    default: 'https://cloudtrucks.zendesk.com/hc/en-us/articles/13976043382295',
    'non-vc':
      'https://your-authority.help.cloudtrucks.com/hc/en-us/articles/13975481536919-CT-Cash-Card-Virtual-Physical-',
  },
  'truck-trailer-ongoing-dot-inspection-requirements': {
    intercom:
      'https://help.cloudtrucks.com/en/articles/5677932-truck-trailer-ongoing-dot-inspection-requirements',
    default:
      'https://cloudtrucks.zendesk.com/hc/en-us/articles/13976302195607-Truck-trailer-DOT-inspection-requirements',
  },
  'starter-pricing-payment': {
    intercom: 'https://help.cloudtrucks.com/en/articles/6871721-starter-pricing-payment',
    default:
      'https://your-authority.help.cloudtrucks.com/hc/en-us/articles/13946216334871-Starter-pricing-payment',
  },
  'driving-with-cloudtrucks': {
    intercom: 'https://help.cloudtrucks.com/en/articles/5142420-driving-with-cloudtrucks',
    default:
      'https://cloudtrucks.zendesk.com/hc/en-us/articles/13975067794199-Driver-employment-experience-qualifications',
  },
  'link-loadboards': {
    intercom: 'https://help.cloudtrucks.com/en/articles/6493117',
    default: 'https://cloudtrucks-your-authority.zendesk.com/hc/en-us/articles/13946261292183',
  },
  'cloudtrucks-plates-program': {
    intercom: 'https://intercom.help/cloudtrucks/en/articles/5236826-does-cloudtrucks-have-a-plates-program',
    default: 'https://cloudtrucks.zendesk.com/hc/en-us/articles/13976337611671-CloudTrucks-plates-program',
  },
  'road-to-independence': {
    intercom:
      'https://intercom.help/cloudtrucks/en/articles/5143027-road-to-independence-lease-to-own-program',

    default:
      'https://cloudtrucks.zendesk.com/hc/en-us/articles/13975092415895-CloudTrucks-Lease-to-Purchase-Partners',
  },
  'ai-consultant': {
    default: '',
  },
  'how-to-search-for-loads': {
    default: 'https://cloudtrucks.zendesk.com/hc/en-us/articles/13975411443735',
    'non-vc':
      'https://your-authority.help.cloudtrucks.com/hc/en-us/articles/13946296949271-How-to-search-for-loads-sort-results',
  },
  'how-to-save-searches': {
    default:
      'https://cloudtrucks.zendesk.com/hc/en-us/articles/20630177179543-How-to-save-searches-search-filters',
  },
  'maintenance-set-aside-learn-more': {
    default: 'https://cloudtrucks.zendesk.com/hc/en-us/articles/21225382361239-Maintenance-Fund-FAQ',
  },
  'maintenance-set-aside-terms': {
    default:
      'https://cloudtrucks.zendesk.com/hc/en-us/articles/21225300666007-Maintenance-Fund-Program-Terms-Conditions',
  },
} as const

export function useHelpCenterUrl(urlKey: keyof typeof urls) {
  const { subscription } = useUserProvider()
  const url = urls[urlKey]

  if (subscription?.isPremium) {
    if ('default' in url) return url.default
    return ''
  }

  if ('non-vc' in url) return url['non-vc']
  if ('default' in url) return url.default

  return ''
}
