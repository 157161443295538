import { Text } from 'shared/components/Text/Text'
import { breakpoints } from 'shared/theme/breakpoints'
import { bgGray } from 'shared/theme/colors'
import { verticallyCentered } from 'shared/theme/mixins'
import styled, { css } from 'styled-components'

import { isConsole } from '../../config/config'

const statusBaseWidth = isConsole() ? '24%' : '40%'

export const StyledCard = styled.div<{ $isDisabled?: boolean }>`
  padding: 13px 40px;
  background: white;
  margin-top: 15px;
  border-radius: 6px;
  text-align: left;
  position: relative;

  @media ${breakpoints.xs} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const StyledCardContent = styled.div<{
  $shouldHideCta?: boolean
  $isInsideAccordion?: boolean
}>`
  ${verticallyCentered};
  grid-template-columns: [statusIcon] 24px [main] ${statusBaseWidth} [status] 1fr [cta] auto;
  grid-column-gap: 20px;

  ${(p) =>
    p.$shouldHideCta &&
    css`
      grid-template-columns: [statusIcon] 24px [main] ${statusBaseWidth} [status] 1fr;
    `}

  ${(p) =>
    p.$isInsideAccordion &&
    css`
      grid-template-columns: [statusIcon] 24px [main] calc(${statusBaseWidth} + 17px) [status] 1fr [cta] auto;
    `}

  @media ${breakpoints.xs} {
    grid-column-gap: 10px;
    grid-template-columns: 24px 1fr auto;
    grid-template-areas:
      'statusIcon main cta'
      'statusIcon status cta';
  }
`

export const StyledMain = styled.div`
  min-height: 45px;
  ${verticallyCentered};
  grid-area: main;
  position: relative;
  top: 2px;

  @media ${breakpoints.xs} {
    min-height: 0;
  }
`

export const StyledIcon = styled.div`
  grid-area: statusIcon;
  ${verticallyCentered};
`

export const StyledStatus = styled.div`
  grid-area: status;
  ${verticallyCentered};
`

export const StyledCta = styled.div`
  grid-area: cta;
  ${verticallyCentered};
`

// TODO: use proper text button
export const StyledTextButton = styled(Text)`
  cursor: pointer;
`

export const StyledSubTasks = styled.div`
  margin-top: 10px;
`

export const StyledSubTask = styled.div`
  position: relative;
  ${verticallyCentered};
  padding: 16px 0 16px 44px;
  grid-template-columns: [statusIcon] 24px [main] calc(${statusBaseWidth} - 24px - 20px + 17px) [status] 1fr [cta] auto;
  grid-column-gap: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    right: -40px;
    border-top: 2px solid ${bgGray};
  }

  ${StyledMain} {
    min-height: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  @media ${breakpoints.xs} {
    padding-left: 30px;
    grid-column-gap: 10px;
    grid-template-columns: 24px 1fr auto;
    grid-template-areas:
      'statusIcon main cta'
      'statusIcon status cta';

    &::before {
      left: -20px;
      right: -20px;
    }
  }
`
