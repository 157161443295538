import axios from 'axios'
import { getCsrfCookieToken } from 'shared/helpers/authHelper'

import { forceClientLogout } from '../redux/actions/auth/authActions'
import { store } from '../redux/store'

export const emailRegex = RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$')
//phone format should be: +11234567899, 1234567891, 111-111-1111, +1 111 222 2222
export const phoneRegex = RegExp(
  '\\+1\\d{10}|\\d{10}|\\d{3}-\\d{3}-\\d{4}|\\+1\\s{0,1}\\d{3}\\s{0,1}\\d{3}\\s\\d{4}'
)

export enum ContactType {
  Email,
  Mobile,
  None,
}

export function getContactType(contact: string): ContactType {
  if (emailRegex.test(contact)) {
    return ContactType.Email
  }

  if (phoneRegex.test(contact)) {
    return ContactType.Mobile
  }

  return ContactType.None
}

export function validContact(contact: string): boolean {
  return phoneRegex.test(contact) || emailRegex.test(contact)
}

export function generateMobileBody(mobileNum: string) {
  if (!mobileNum.startsWith('+1')) {
    mobileNum = '+1' + mobileNum
  }
  return { mobile: mobileNum }
}

//axios interceptor to add csrf token to every request
export function setCsrfHeader() {
  axios.interceptors.request.use((config) => {
    if (config.method !== 'get') {
      let token = getCsrfCookieToken()
      config.headers['Content-Type'] = 'application/json'
      config.headers['x-csrftoken'] = token
    }
    return config
  })
}

//axios interceptor to add x headers to every request
export function setXHeaders() {
  axios.interceptors.request.use((config) => {
    config.headers['X-Referer'] = window.location.href
    config.headers['X-Client'] = 'web'
    config.headers['X-Web-App-Release'] = process.env.REACT_APP_RELEASE_VERSION
    return config
  })
}

//axios interceptor to handle unauthorized response
export function handleUnauthorizedRequest() {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const status = error?.response?.status
      if (status === 401) {
        store.dispatch(forceClientLogout())
      }
      throw error
    }
  )
}
