import { useDispatch } from 'react-redux'
import { showSuccessBanner } from 'shared/actions/ui/actions'
import { BannerProps } from 'shared/components/Context/UserContext'

export function useSuccessMessage() {
  const dispatch = useDispatch()

  return function successMessage({ message, title }: BannerProps | any) {
    dispatch(showSuccessBanner({ message, title }))
  }
}
