export enum CacheKeys {
  JOBS = 'JOBS',
  JOB_HUMAN_ID = 'JOB_HUMAN_ID',
  AVAILABLE_TIME = 'AVAILABLE_TIME',
  DOCUMENTS = 'DOCUMENTS',
  NOTES = 'NOTES',
  NOTE_AVAILABLE_TAGS = 'NOTE_AVAILABLE_TAGS',
  NOTE_ADDED_TAGS = 'NOTE_ADDED_TAGS',
  USER = 'USER',
  CHECKLIST = 'CHECKLIST',
  DRIVING_VIOLATIONS = 'DRIVING_VIOLATIONS',
  DRIVING_ACCIDENTS = 'DRIVING_ACCIDENTS',
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  EMPLOYMENT_HISTORY = 'EMPLOYMENT_HISTORY',
  EMPLOYMENT_HISTORY_ITEM = 'EMPLOYMENT_HISTORY_ITEM',
  SAFETY_EVENTS = 'SAFETY_EVENTS',
  SAFETY_SCORE = 'SAFETY_SCORE',
  SAFETY_SCORE_RANGES = 'SAFETY_SCORE_RANGES',
  PERFORMANCE_EVENTS = 'PERFORMANCE_EVENTS',
  PERFORMANCE_SCORE = 'PERFORMANCE_SCORE',
  PERFORMANCE_SCORE_RANGES = 'PERFORMANCE_SCORE_RANGES',
  TEAM = 'TEAM',
  TRUCK = 'TRUCK',
  TEAM_TRUCKS = 'TEAM_TRUCKS',
  TRAILER = 'TRAILER',
  TEAM_TRAILERS = 'TEAM_TRAILERS',
  DEVICE_VERSIONS = 'DEVICE_VERSIONS',
  ESIGN_LEASE_AGREEMENT = 'ESIGN_LEASE_AGREEMENT',
  STAFF_USERS = 'STAFF_USERS',
  REFERRALS = 'REFERRALS',
  MAINTENANCE_SET_ASIDE = 'MAINTENANCE_SET_ASIDE',
}
