import { snackbarsReducer } from 'shared/reducers/snackbarsReducer'

import { combineReducers } from 'redux'
import { createMigrate, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { LOGOUT_USER } from '../actions/auth/authActionTypes'
import { ApplicationState } from '../store/models'
import apiCallStatusReducer from './apiStatusReducer'
import authReducer from './authReducer'
import cashReducer from './cashReducer'
import { consultantReducer } from './consultant'
import jobsReducer from './jobs'
import { migrations } from './migrations'
import settingsReducer from './settingsReducer'
import teamsReducer from './teamsReducer'
import usersReducer from './usersReducer'
// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
const appReducer: any = combineReducers({
  auth: authReducer,
  apiCalls: apiCallStatusReducer,
  jobs: jobsReducer,
  user: usersReducer,
  team: teamsReducer,
  settings: settingsReducer,
  snackbars: snackbarsReducer,
  cash: cashReducer,
  consultant: consultantReducer,
})

const rootReducer = (state: ApplicationState | undefined, action: any) => {
  if (action.type === LOGOUT_USER) {
    // setting state to undefined resets all reducers
    // https://netbasal.com/how-to-secure-your-users-data-after-logout-in-redux-30468c6848e8
    state = undefined
  }

  return appReducer(state, action)
}

// TODO I think we need to reexamine what allow redux persistor to persist
const persistConfig = {
  key: 'root',
  version: 0,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['apiCalls', 'settings', 'jobs', 'snackbars', 'consultant'],
  migrate: createMigrate(migrations, { debug: true }),
}

const reducers = persistReducer<ApplicationState | undefined, any>(persistConfig, rootReducer)
// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default reducers
