import _ from 'lodash'
import { GenericLoadStopProgress, Job } from 'shared/models/job'
import { LoadStop, LoadStopProgressStatus, LoadStopType, TrackingStatus } from 'shared/models/load'

import { EquipmentType } from '../models/equipment'
import { getTimeDifference, replaceUnderscore } from './formatters'

export const getJobTrackingStatusToDisplay = <T extends GenericLoadStopProgress>(
  trackingStatus: TrackingStatus,
  loadStopProgress: T | undefined,
  showHosEta: boolean
): string => {
  if (loadStopProgress?.status === LoadStopProgressStatus.AT_FACILITY) {
    return 'Arrived'
  }

  const isEnRoute = loadStopProgress?.status === LoadStopProgressStatus.EN_ROUTE

  if (isEnRoute && trackingStatus === TrackingStatus.ERROR) {
    return 'Tracking unavailable'
  }

  const isLate = [TrackingStatus.LATE, TrackingStatus.MAYBE_LATE].includes(trackingStatus)
  if (isEnRoute && isLate && showHosEta && loadStopProgress?.lastScheduleEvent) {
    const withHosText = loadStopProgress?.lastScheduleEvent?.offDutyHours ? 'with HOS' : ''
    return `Late ${withHosText} - ${getTimeDifference(
      loadStopProgress?.lastScheduleEvent?.driveDateEnd,
      loadStopProgress?.stop.dateEnd
    )}`
  }

  if (isEnRoute) {
    return _.startCase(trackingStatus.toLowerCase())
  }

  if (loadStopProgress) {
    return _.startCase(loadStopProgress.status.toLowerCase())
  }

  return ''
}

export const getTripRate = (loadTripRate: number | string, confirmedRate?: number): number => {
  if (confirmedRate) {
    return confirmedRate
  }
  return loadTripRate && loadTripRate !== '0.00' ? Number(loadTripRate) : 0
}

export const formatTripRate = (tripRate: number): string => {
  return Number(tripRate).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export const getTripRatePerMile = (tripRate: number, tripDistance: number): string => {
  return tripRate && tripDistance ? Number(tripRate / tripDistance).toFixed(2) : '0'
}

export const getLoadStopOrigin = (job?: Job) => {
  if (!job) {
    return null
  }
  const origin = job.load.stops.find((stop) => stop.type === LoadStopType.ORIGIN) as LoadStop
  return origin || null
}

export const formatEquipments = (equipments: EquipmentType[]) => {
  return !!equipments && equipments.length > 0 ? replaceUnderscore(equipments[0].toLowerCase()) : ''
}

export const loadLocationFormatter = (stop?: LoadStop) => {
  const stopCity = stop?.locationCity.toLowerCase() || ''
  const stopState = stop?.locationState || ''
  const stopAddress1 = stop?.locationAddress1 || ''

  return stopAddress1 ? `${stopAddress1}, ${stopCity}, ${stopState}, ` : `${stopCity}, ${stopState}`
}
