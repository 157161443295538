import LuxonAdapter from '@date-io/luxon'
import { formatDistance, isEqual, isSameDay, isToday } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

import { Load, LoadStop } from 'models/jobs'
import { Address, Team } from '../models'
import { EquipmentType } from '../models/equipment'
import { getState } from './states'

export { getDateFormatYearMonth } from 'shared/helpers/formatters'

// converts 10/04/1990 -> 1990-10-04
export function convertDateForAPI(dateStr: string | undefined) {
  if (!dateStr) {
    return undefined
  }
  let [month, day, year] = dateStr.split('/')
  return `${year}-${month}-${day}`
}

// converts 1990-10-04 -> 10/04/1990
export const convertDateForUI = (dateStr: string | undefined): string => {
  if (!dateStr) {
    return ''
  }
  let [year, month, day] = dateStr.split('-')
  return dateStr ? `${month}/${day}/${year}` : ''
}

//get valid mobile value without () and empty spaces
export const convertMobileForAPI = (mobile: string) => {
  return mobile.replace(/[)( \s_]/g, '')
}

//format mobile in +1(424)574 - 48347 format
export const formatMobile = (mobile: string): string => {
  let result = mobile
  if (!result) {
    return ''
  }
  if (!!mobile && mobile.startsWith('+1')) {
    result = mobile.slice(2)
  }
  return `+1 (${result.substr(0, 3)}) ${result.substr(3, 3)} - ${result.substr(6, 4)}`
}

// converts datetime string to Day, Month day(numeric)
export const getDateFormat = (date: string) => {
  let dateObj = new Date(date)
  const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' }
  return dateObj.toLocaleString('en-us', options)
}

// converts date time string to Month day(number)
export const getDateFormatMonthDay = (date: string) => {
  let dateObj = new Date(date)
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' }
  return dateObj.toLocaleString('en-us', options)
}

// converts datetime string to month/day/year, hh:mm:ss
export const getDateAndTime = (date: string) => {
  // return empty string if date not set.
  if (!date) return ''

  let dateObj = new Date(date)
  return dateObj.toLocaleString('en-us')
}

//converts dateTime string to DayWeek • Month Day
export const getDateFormattedShort = (
  date: string | undefined,
  timezone: string | undefined,
  commaSeparator?: boolean
) => {
  const dateFormat = commaSeparator ? 'EEE, MMM d' : 'EEE • MMM d'
  if (date && timezone) {
    const dateObj = utcToZonedTime(date, timezone)
    return format(dateObj, dateFormat, { timeZone: timezone })
  }
  return ''
}

export const getDateFormattedLong = (date: string, timezone: string) => {
  if (date) {
    const dateObj = utcToZonedTime(date, timezone)
    return format(dateObj, 'EEE, MMM d, yyyy', { timeZone: timezone })
  }
  return ''
}

//converts dateTime string to DayWeek • Month Day • Time Timezone
export const getDateTimeZone = (date: string, timezone: string, destinationDate?: boolean) => {
  if (date) {
    const dateObj = utcToZonedTime(date, timezone)
    return format(dateObj, 'EEE • MMM d • p zzz', { timeZone: timezone })
  } else if (destinationDate) {
    return 'Time to be confirmed'
  }
  return ''
}

export const getDateTimeWindow = (
  dateStart: string | Date,
  dateEnd: string | Date,
  timezone: string,
  {
    destinationDate,
    alwaysShowTime,
    addBullet,
  }: { destinationDate?: boolean; alwaysShowTime?: boolean; addBullet?: boolean } | undefined = {}
) => {
  if (!!dateStart) {
    const dateStartObj = utcToZonedTime(dateStart, timezone)
    const dateEndObj = utcToZonedTime(dateEnd || dateStart, timezone)

    if (!isEqual(dateStartObj, dateEndObj)) {
      //time window is in same day
      if (isSameDay(dateStartObj, dateEndObj)) {
        return `${format(dateStartObj, `MMM d${addBullet ? ' • ' : ' '}HH:mm`, { timeZone: timezone })} -
         ${format(dateEndObj, 'HH:mm zzz', { timeZone: timezone })}`
      } else if (alwaysShowTime) {
        //time window is a few days
        return `${format(dateStartObj, `MMM d${addBullet ? ' • ' : ' '}HH:mm`, {
          timeZone: timezone,
        })} - ${format(dateEndObj, `MMM d${addBullet ? ' • ' : ' '}HH:mm zzz`, { timeZone: timezone })}`
      } else {
        //time window is a few days
        return `${format(dateStartObj, `MMM d${addBullet ? ' • ' : ' '}HH:mm`, {
          timeZone: timezone,
        })} - ${format(dateEndObj, 'MMM d zzz', { timeZone: timezone })}`
      }
    } else return format(dateStartObj, `MMM d${addBullet ? ' • ' : ' '}HH:mm zzz`, { timeZone: timezone })
  } else if (destinationDate) {
    return 'Time to be confirmed'
  }
  return ''
}

// temporary workaround converts ["Dry van"] to ["DRY_VAN"]
// TODO change return result from API
export const formattedEquipment = (equipment: string[]) => {
  return equipment.map((truck) => truck.replace(/\s/g, '_').toUpperCase())
}
//format load equipments and return equipment name
export const formatEquipments = (equipments: EquipmentType[]) => {
  return !!equipments && equipments.length > 0 ? replaceUnderscore(equipments[0].toLowerCase()) : ''
}

export const replaceUnderscore = (input: string) => {
  return input ? input.replace(/_/g, ' ') : ''
}

// temporary fix since we don't get city in sign up flow
export const formatTeamAddress = (team: Team) => {
  if (!!team.location_city && !!team.location_state) {
    return `${team.location_city}, ${team.location_state}`
  }
  const state = getState(team.location_state as string)
  return `${state?.name}`
}
//return street, city, state, zip code
export const formatAddress = (address: Address): string => {
  return [address.line1, address.city, address.state, address.zip].join(', ')
}

//format Address, City, State StateCode, Country or  City, State StateCode, Country -> City, State
export const formatLocation = (location: string) => {
  const values = location.split(',')

  if (values.length === 4) {
    //remove address
    values.shift()
  }
  const city = values[0].trim()
  const state = values[1].trim().substring(0, 2)
  return city + ', ' + state
}

export const getTimeAndTimeZone = (date?: string) => {
  if (date) {
    let dateObj = new Date(date)
    let time = format(dateObj, 'hh:mm a z')
    let dateFormatted = format(dateObj, 'MMM dd hh:mm')
    let todaysDate = isToday(dateObj)
    return todaysDate ? 'Today ' + time : dateFormatted
  }
  return ''
}

export const getStopLocation = (location_city?: string, location_state?: string) => {
  const city = !!location_city ? location_city.toLowerCase() : ''
  const state = !!location_state ? location_state : ''

  if (!!city && !!state) return `${city}, ${state}`
  else if (!!city) return city
  else if (!!state) return state
  else return ''
}

export const ctRangeFormatter = (load: Load) => {
  return load?.trip_distance_mi
    ? `$${(load?.estimated_rate_min / load?.trip_distance_mi).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })} - $${(load?.estimated_rate_max / load?.trip_distance_mi).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })} /mi`
    : ''
}

export const ctEstimateFormatter = (load: Load) =>
  `$${load!.estimated_rate_min.toLocaleString('en-US')} - $${load?.estimated_rate_max.toLocaleString(
    'en-US'
  )}`

export const loadLocationFormatter = (stop?: LoadStop) => {
  const stopCity = stop?.location_city.toLowerCase() || ''
  const stopState = stop?.location_state || ''
  const stopAddress1 = stop?.location_address1 || ''

  return stopAddress1 ? `${stopAddress1}, ${stopCity}, ${stopState}, ` : `${stopCity}, ${stopState}`
}

export const luxon = new LuxonAdapter({ locale: 'en' })

export function formatDateWithBullet(d: string) {
  const date = new Date(d)
  return `${date.toLocaleDateString('en-US', { weekday: 'long' })} • ${date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  })}`
}

export function formatDateForApi(date: string | Date) {
  return format(new Date(date), `yyyy-MM-dd'T'HH:mm:00`)
}

export function formatDateForApiWithoutTime(date: string | Date) {
  return format(new Date(date), `yyyy-MM-dd`)
}

const MS_PER_MINUTE = 60000
export function timeAgoFromMinutes(minutesAgo: number) {
  const postedDate = new Date(new Date().getTime() - minutesAgo * MS_PER_MINUTE)
  return timeAgo(postedDate)
}

export const timeAgo = (dateTime: string | Date) => {
  return formatDistance(new Date(dateTime), new Date(), { addSuffix: true })
}

export function currencyToNumber(val: string | number) {
  const formated = String(val).replaceAll(',', '')
  return Number(formated)
}
