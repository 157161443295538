import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useState } from 'react'
import ReactCopyToClipboard from 'react-copy-to-clipboard'
import { styled } from 'styled-components'

import { kellyGreenD1, royalBlue } from '../../theme/colors'
import { FlexBox } from '../Base/FlexBox'
import { Text, TextProps } from '../Text/Text'

interface CopyToClipBoardProps {
  text: string
  textProps?: Partial<TextProps>
  children: React.ReactNode
  success?: React.ReactNode
}

export function CopyToClipBoard({ text, children, success, textProps }: CopyToClipBoardProps) {
  const [isCopied, setIsCopied] = useState(false)

  function onCopy() {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <ReactCopyToClipboard text={text} onCopy={onCopy}>
      {isCopied ? (
        success || (
          <FlexBox alignItems="center" gap="5px">
            <StyledCheckCircleOutlineOutlinedIcon />
            <Text variant="paragraph2" color={kellyGreenD1} fontWeight={600} {...textProps}>
              Copied
            </Text>
          </FlexBox>
        )
      ) : (
        <span>{children}</span>
      )}
    </ReactCopyToClipboard>
  )
}

export function HoverCopyToClipBoard({ text, children }: CopyToClipBoardProps) {
  const [isCopied, setIsCopied] = useState(false)

  function onCopy() {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <ReactCopyToClipboard text={text} onCopy={onCopy}>
      <HoverContainer alignItems="center" gap="8px">
        {children}
        {isCopied ? (
          <FlexBox alignItems="center" gap="5px">
            <StyledCheckCircleOutlineOutlinedIcon />
            <Text variant="paragraph2" color={kellyGreenD1} fontWeight={600} mt={0.3}>
              Copied
            </Text>
          </FlexBox>
        ) : (
          <HoverCopyToClipBoardIcon />
        )}
      </HoverContainer>
    </ReactCopyToClipboard>
  )
}

export function CopyToClipBoardIcon({ className, text }: { className?: string; text: string }) {
  return (
    <FlexBox className={className} styles="position: relative; top: -2px; height: 17px;">
      <CopyToClipBoard text={text} success={<StyledCheckCircleOutlineOutlinedIcon />}>
        <StyledFileCopyIcon />
      </CopyToClipBoard>
    </FlexBox>
  )
}

const StyledCheckCircleOutlineOutlinedIcon = styled(CheckCircleOutlineOutlinedIcon)`
  margin-top: -2.4px;
  font-size: 17px;
  color: ${kellyGreenD1} !important;
`

const StyledFileCopyIcon = styled(FileCopyIcon)`
  font-size: 17px;
  cursor: pointer;
  color: ${royalBlue} !important;
`

const HoverContainer = styled(FlexBox)`
  &:hover {
    svg {
      display: block !important;
    }
  }
`

const HoverCopyToClipBoardIcon = styled(StyledFileCopyIcon)`
  display: none;
`
