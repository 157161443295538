import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'
import { Handler } from 'shared/helpers/typeHelper'
import styled from 'styled-components'

import { useAnalyticsEvent } from '../../../../hooks/useAnalyticsEvent'
import { currentUserSelector } from '../../../../redux/selectors/users'
import { PROFILE_PATH } from '../../../../Routes'

interface AvatarMenuProps {
  handleLogout: Handler
}

const StyledUserAvatar = styled(UserAvatar)`
  margin-left: 20px;
`

export const AvatarMenu = ({ handleLogout }: AvatarMenuProps) => {
  const track = useAnalyticsEvent()
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()
  const currentUser = useSelector(currentUserSelector)

  const handleClick = (event: any) => {
    track('nav_avatar_click')
    setAnchorEl(event.currentTarget)
  }

  const handleProfileClick = () => {
    history.push(PROFILE_PATH)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <StyledUserAvatar
        onClick={handleClick}
        width="40px"
        height="40px"
        fontSize="11px"
        userName={`${currentUser.first_name} ${currentUser.last_name}`}
      />
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 60, horizontal: -50 }}
        data-testid="menu-options"
      >
        <MenuItem onClick={handleProfileClick}>See my profile</MenuItem>
        <MenuItem onClick={handleLogout} data-testid="logout">
          Log Out
        </MenuItem>
      </Menu>
    </>
  )
}
