import { useQuery, UseQueryOptions } from 'react-query'
import { FeatureId } from 'shared/models/productSubscription'

import { CacheKeys } from '../../helpers/cacheKeys'
import { fetchUserOnboardingChecklist, OnboardingChecklist } from '../../models/onboarding'

export function useOnboardingChecklist(
  userId: string,
  { enabled = true, refetchOnMount = false, featureId }: UseQueryOptions & { featureId?: FeatureId } = {}
) {
  const { data, isLoading, isFetching, refetch } = useQuery(
    `${CacheKeys.CHECKLIST}-${userId}-${featureId || ''}`,
    () => fetchUserOnboardingChecklist(userId, featureId),
    {
      enabled,
      refetchOnMount,
      refetchOnWindowFocus: false,
      retry: true,
      retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    }
  )

  const commonProps = {
    isLoading,
    isFetching,
    refetch,
  }

  if (data) {
    if (data.flexChecklist)
      return {
        checklist: data.flexChecklist,
        roadChecklist: null,
        allChecklistItems: data.flexChecklist,
        ...commonProps,
      }
    else if (data.cashChecklist)
      return {
        checklist: data.cashChecklist,
        roadChecklist: null,
        allChecklistItems: data.cashChecklist,
        ...commonProps,
      }
    else if (data.applicationChecklist)
      return {
        checklist: data.applicationChecklist,
        roadChecklist: data.roadPreparationChecklist || null,
        allChecklistItems: [
          ...(data.applicationChecklist || []),
          ...(data.roadPreparationChecklist || []),
        ] as Readonly<OnboardingChecklist[]>,
        ...commonProps,
      }
  }

  return { checklist: [], roadChecklist: null, allChecklistItems: [], ...commonProps }
}
