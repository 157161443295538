import { User } from 'models'
import * as types from '../actions/users/actionTypes'

let initState: User = {
  id: '',
  first_name: '',
  last_name: '',
  mobile: '',
  driver: {},
  mobile_verified: false,
  email: '',
  email_verified: false,
  is_active: false,
  is_staff: false,
  is_team_admin: false,
  is_team_driver: false,
  last_login: '',
  team: '',
  date_joined: '',
  invitedUser: {
    id: '',
    first_name: '',
    last_name: '',
    shipping_address: { id: '', line1: '', line2: '', city: '', state: '', country: '', zip: '' },
  },
  shipping_address: { id: '', line1: '', line2: '', city: '', state: '', country: '', zip: '' },
  featureFlags: {},
}

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default function usersReducer(state = initState, action: any) {
  switch (action.type) {
    case types.RESET_CURRENT_USER_INFO: {
      return initState
    }
    case types.SET_CURRENT_USER_INFO: {
      const { feature_flags, ...user } = action.user
      return { ...state, ...user, featureFlags: feature_flags || state.featureFlags }
    }
    case types.USER_INVITE_SUCCESS:
      return { ...state, invitedUser: action.invitedUser }
    case types.SAVE_MEDICAL_CARD_CURRENT_USER:
      return { ...state, ...action.data }
    case types.SAVE_MEDICAL_CARD_INVITED_USER:
      return { ...state, invitedUser: action.data }
    case types.SAVE_BACKGROUND_CHECK_STATUS_CURRENT_USER:
      return {
        ...state,
        background_check_status: action.status,
      }
    case types.SAVE_USER_LOCATION:
      return { ...state, location: action.location }
    default:
      return state || initState
  }
}
