import { formatISO } from 'date-fns'
import queryString from 'query-string'

import { SortType } from '../models'
import { EquipmentType } from '../models/equipment'
import { initialQueryFilter, QueryFilter } from '../models/jobs'

export type SearchPropsType = {
  pickupLocation: {
    value: string
    update: (value: string) => void
    label: string
  }
  dropoffLocation: {
    value: string
    update: (value: string) => void
    label: string
  }
  pickupRadius: {
    value: number
    update: (value: number) => void
  }
  dropOffRadius: {
    value: number
    update: (value: number) => void
  }
  selectedDistances: {
    value: string[]
    update: (value: string[]) => void
  }
  selectedDate: {
    value: Date | null
    update: (value: Date | null) => void
  }
  selectedEquipment: {
    value: EquipmentType[]
    update: (value: EquipmentType[]) => void
  }
  selectedBookingTypes: {
    value: string[]
    update: (value: string[]) => void
  }
  selectedBookingSources: {
    value: string[]
    update: (value: string[]) => void
  }
  selectedMaxLoadWeight: {
    value: number
    update: (value: number) => void
  }
  selectedTimePostedType: {
    value: 'newerThan' | 'olderThan'
    update: (value: 'newerThan' | 'olderThan') => void
  }
  selectedNewerThanTime: {
    value: number
    update: (value: number) => void
  }
  selectedOlderThanTime: {
    value: number
    update: (value: number) => void
  }
  selectedRequestedStates: {
    value: string[]
    update: (value: string[]) => void
  }
  validationErrors: string[][]
}

export const searchRadiusValues = [25, 50, 100, 150, 200, 250, 300, 350, 400]

export const serializeSearchForUrlParams = (search: QueryFilter): string => {
  const params = {
    origin_location: search.origin_location,
    origin_range_mi__max: search.origin_range_mi__max,
    origin_pickup_date__min: search.origin_pickup_date__min,
    dest_location: search.dest_location,
    dest_range_mi__max: search.dest_range_mi__max,
    equipment: search.equipment?.join(','),
    total_pay__min: search.total_pay__min,
    trip_rate__min: search.trip_rate__min,
    sort_type: search.sort_type,
    booking_type: search.booking_type,
    trip_distance_mi__max: search.trip_distance_mi__max,
    trip_distance_mi__min: search.trip_distance_mi__min,
    trip_distances: search.trip_distances?.join(','),
    date_queried: search.date_queried,
    load_providers: search.load_providers?.join(','),
    age_min__max: search.age_min__max,
    age_min__min: search.age_min__min,
    truck_weight_lb__max: search.truck_weight_lb__max,
    requested_states: search.requested_states?.join(','),
    is_offline_book_compatible: search.is_offline_book_compatible,
    async: search.async,
  }

  return queryString.stringify(params)
}

function parseIfString(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string') {
    return { [key]: params[key] }
  }

  return {}
}

function parseIfInt(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string' && !isNaN(parseInt(params[key] as string))) {
    return { [key]: parseInt(params[key] as string) }
  }

  return {}
}

function parseIfStringArr(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string' && (params[key] as string) !== '') {
    return { [key]: (params[key] as string).split(',') }
  }

  return {}
}

function parseIfBoolean(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string') {
    return { [key]: params[key] === 'true' }
  }

  return {}
}

function parseIfSortType(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string' && Object.keys(SortType).includes(params[key] as string)) {
    return { [key]: params[key] as SortType }
  }

  return {}
}

export const parseUrlParamsForSearch = (searchStr: string): Partial<QueryFilter> => {
  const params = queryString.parse(searchStr)

  return {
    ...parseIfString(params, 'origin_location'),
    ...parseIfInt(params, 'origin_range_mi__max'),
    ...parseIfString(params, 'origin_pickup_date__min'),
    ...parseIfString(params, 'dest_location'),
    ...parseIfInt(params, 'dest_range_mi__max'),
    ...parseIfStringArr(params, 'equipment'),
    ...parseIfInt(params, 'total_pay__min'),
    ...parseIfInt(params, 'trip_rate__min'),
    ...parseIfSortType(params, 'sort_type'),
    ...parseIfString(params, 'booking_type'),
    ...parseIfInt(params, 'trip_distance_mi__max'),
    ...parseIfInt(params, 'trip_distance_mi__min'),
    ...parseIfStringArr(params, 'trip_distances'),
    ...parseIfString(params, 'date_queried'),
    ...parseIfStringArr(params, 'load_providers'),
    ...parseIfInt(params, 'age_min__min'),
    ...parseIfInt(params, 'age_min__max'),
    ...parseIfInt(params, 'truck_weight_lb__max'),
    ...parseIfStringArr(params, 'requested_states'),
    ...parseIfBoolean(params, 'is_offline_book_compatible'),
    ...parseIfBoolean(params, 'async'),
  }
}

export const defaultReloadQueryFilter: QueryFilter = {
  // TODO: @public-whats-next update this default query filter to be more realistic
  ...initialQueryFilter,
  origin_location: '', // converts to current location but isn't necessary for the query for reload
  origin_range_mi__max: 150,
  origin_pickup_date__min: formatISO(new Date()),
  dest_location: '',
  dest_range_mi__max: 150,
  equipment: [EquipmentType.POWER_ONLY, EquipmentType.DRY_VAN, EquipmentType.FLATBED, EquipmentType.REEFER],
  sort_type: SortType.REVENUE_PER_HOUR,
  booking_type: 'ALL',
  trip_distances: ['Short', 'Local', 'Long'],
  age_min__max: 120,
  truck_weight_lb__max: 45000,
  is_offline_book_compatible: true,
}
