import { Handler } from 'shared/helpers/typeHelper'

export const loadGoogleMapApis = (googleApiKey: string, onLoad: Handler) => {
  const position = document.querySelector('head')
  if (!position) return
  const maps = document.getElementById('google-maps')
  // load maps if we haven't already
  if (!maps) {
    ;(window as any).initGoogleMap = onLoad
    const script = document.createElement('script')
    script.setAttribute('id', 'google-maps')
    script.async = true
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=initGoogleMap`
    position.appendChild(script)
  }
}
