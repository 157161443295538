import { useDispatch } from 'react-redux'
import { showWarningBanner } from 'shared/actions/ui/actions'
import { BannerProps } from 'shared/components/Context/UserContext'

export function useWarningMessage() {
  const dispatch = useDispatch()

  return function warningMessage({ message, title }: BannerProps | any) {
    dispatch(showWarningBanner({ message, title }))
  }
}
