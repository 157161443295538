import React, { ReactPortal, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { breakpoints } from 'shared/theme/breakpoints'
import styled from 'styled-components'

import { useUserProvider } from '../Context/UserContext'

type NavLeftProps = {
  children?: React.ReactNode
  className?: string
}

export const NAV_LEFT_WRAP = 'nav-left-wrap'

export function NavLeft({ children, className }: NavLeftProps): ReactPortal | null {
  const [wrap, setWrap] = useState(document.getElementById(NAV_LEFT_WRAP))
  const { isSidebarVisible } = useUserProvider()

  useEffect(() => {
    setWrap(document.getElementById(NAV_LEFT_WRAP))
  }, [])

  if (!wrap) {
    console.warn('Missing nav left wrap')
    return null
  }

  return ReactDOM.createPortal(
    <Wrap className={className} $showSidebar={isSidebarVisible}>
      {children}
    </Wrap>,
    wrap
  )
}

const Wrap = styled.div<{ $showSidebar: boolean }>`
  cursor: pointer;
  line-height: 1.2;
  margin: 5px 15px 0 0;

  @media ${breakpoints.xs} {
    ${({ $showSidebar }) => $showSidebar && 'margin-left: 25px;'}
  }
`
