import axios from 'axios'
import queryString from 'query-string'

import { parseLoadDetails } from '../helpers/load'
import { PaginatedResults } from '../helpers/typeHelper'
import { toCamelCase, toSnakeCase } from '../helpers/utils'
import { JobInvoice } from './jobInvoice'
import { Load, LoadStopProgress, LoadStopProgressStatus, TrackingStatus } from './load'
import { SlimTeam } from './team'

export interface Job {
  assignedTo?: AssignedUser
  assignedTruck?: AssignedTruck
  bookingType: BookingTypes
  brokerPolicy: string
  confirmedRate: number
  createdBy: string
  notes: string
  dateCreated: string
  dateUpdated: string
  humanId: string
  id: string
  status: JobStatus
  load: Load
  statements: Statement[]
  statusDetail: JobStatusDetail
  humanStatusDetail: string
  humanStatusDetailColor: string
  documents?: Document[]
  userSubmittedBidMax?: number
  userSubmittedBidMin?: number
  isCanceled: boolean
  isFactored: boolean
  isApproved: boolean
  isDeliveryRejected: boolean
  isInProgress: boolean
  showCancelAction: boolean
  showRequestCancellationAction: boolean
  showCompleteAction: boolean
  showDeleteAction: boolean
  showEditJobAction: boolean
  showRemoveAction: boolean
  showReportIssueAction: boolean
  showUploadDocumentAction: boolean
  showAssignToAction: boolean
  showTrackingAction: boolean
  showCreateInvoiceAction: boolean
  showNotifyFactoringCompanyAction: boolean
  loadStopProgresses?: LoadStopProgress[]
  trackingCurrentLoadStopProgress?: LoadStopProgress
  trackingStatus?: TrackingStatus
  trackingType?: JobTrackingType
  sentByEmail?: boolean
  invoices: JobInvoice[]
  team?: SlimTeam
}

export interface Document {
  dateCreated: string
  dateUpdated: string
  description: string
  isEditable: boolean
  id: string
  job: string
  type: DocumentType
  uploadedBy: string
  attachments: Attachment[]
}

export interface Attachment {
  file: string
  mimetype: string
  originalFilename: string
}

export interface Statement {
  dateCreated: string
  dateUpdated: string
  description: string
  id: string
  leaseFee: number
  leaseFeePercent: number
  lineItems: LineItem[]
  serviceFee: number
  serviceFeePercent: number
  subtotal: number
  total: number
  feeItems: FeeItem[]
  type: string
}

export interface LineItem {
  amount: string
  dateCreated: string
  dateUpdated: string
  description: string
  type: string
}

export interface FeeItem {
  description: string
  amount: string
  dateCreated: string
  dateUpdated: string
}

export interface AssignedUser {
  id: string
  firstName: string
  lastName: string
}

export interface AssignedTruck {
  id: string
  vinNumber: string
}

export interface GenericLoadStopProgress {
  stop: any
  status: LoadStopProgressStatus
  lastScheduleEvent: ScheduleEvent
}

export interface ScheduleEvent {
  driveDateEnd: string
  offDutyHours: number
  onDutyHours: number
  totalHours: number
  scheduleId: string
}

export enum JobTrackingType {
  MOBILE = 'MOBILE',
  ELD = 'ELD',
}

export enum JobStatusDetail {
  BIDDING_REQUIRED = 'BIDDING_REQUIRED',
  BOOKED_OFFLINE = 'BOOKED_OFFLINE',
  ACTIVE_NEGOTIATION = 'ACTIVE_NEGOTIATION',
  BROKER_SETUP_REQUIRED = 'BROKER_SETUP_REQUIRED',
  AWAITING_RATE_CONFIRMATION = 'AWAITING_RATE_CONFIRMATION',
  REVIEWING_RATE_CONFIRMATION = 'REVIEWING_RATE_CONFIRMATION',
  ABORTED = 'ABORTED',
  JOB_APPROVED = 'JOB_APPROVED',
  PAYMENT_ISSUED = 'PAYMENT_ISSUED',
  REIMBURSED = 'REIMBURSED',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  PENDING_DELIVERY_VERIFICATION = 'PENDING_DELIVERY_VERIFICATION',
  DELIVERY_VERIFICATION_ACCEPTED = 'DELIVERY_VERIFICATION_ACCEPTED', // TODO (ezennag-delete)
  PENDING_PAYMENT_VERIFICATION = 'PENDING_PAYMENT_VERIFICATION',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  DELIVERY_VERIFICATION_REJECTED = 'DELIVERY_VERIFICATION_REJECTED',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  REQUESTED_CANCELLATION = 'REQUESTED_CANCELLATION',
  NF_BOOKED = 'NF_BOOKED',
  NF_COMPLETED = 'NF_COMPLETED',
  NF_REMOVED = 'NF_REMOVED',
}

export const JobConsoleStatusDetailUserFacingMap: Record<JobStatusDetail, string> = {
  [JobStatusDetail.BIDDING_REQUIRED]: 'Bidding Required',
  [JobStatusDetail.BOOKED_OFFLINE]: 'Booked Offline',
  [JobStatusDetail.ACTIVE_NEGOTIATION]: 'Active Negotiation',
  [JobStatusDetail.BROKER_SETUP_REQUIRED]: 'Broker Setup Required',
  [JobStatusDetail.AWAITING_RATE_CONFIRMATION]: 'Awaiting Rate Confirmation',
  [JobStatusDetail.REVIEWING_RATE_CONFIRMATION]: 'Reviewing Rate Confirmation',
  [JobStatusDetail.ABORTED]: 'Aborted',
  [JobStatusDetail.JOB_APPROVED]: 'Job Approved',
  [JobStatusDetail.IN_PROGRESS]: 'In Progress',
  [JobStatusDetail.PENDING_DELIVERY_VERIFICATION]: 'Pending Delivery Verification',
  [JobStatusDetail.PAYMENT_REQUIRED]: 'Payment Required',
  [JobStatusDetail.DELIVERY_VERIFICATION_REJECTED]: 'Delivery Verification Rejected',
  [JobStatusDetail.PENDING_PAYMENT_VERIFICATION]: 'Pending Payment Verification',
  [JobStatusDetail.PAYMENT_ISSUED]: 'Payment Issued',
  [JobStatusDetail.CANCELED]: 'Canceled',
  [JobStatusDetail.REQUESTED_CANCELLATION]: 'Cancellation Requested',
  [JobStatusDetail.PENDING_CANCELLATION]: 'Pending Cancellation',
  [JobStatusDetail.REIMBURSED]: 'Reimbursed',
  [JobStatusDetail.COMPLETED]: 'Completed',
  [JobStatusDetail.VERIFIED]: 'Verified',
  [JobStatusDetail.REJECTED]: 'Rejected',
  [JobStatusDetail.DELIVERY_VERIFICATION_ACCEPTED]: 'Delivery verification accepted',
  [JobStatusDetail.NF_BOOKED]: 'Booked',
  [JobStatusDetail.NF_COMPLETED]: 'Delivery completed',
  [JobStatusDetail.NF_REMOVED]: 'Removed',
}

export function getJobConsoleStatusDetail(jobStatus: JobStatusDetail, humanStatus?: string) {
  const status = JobConsoleStatusDetailUserFacingMap[jobStatus]
  if (status) return status
  return humanStatus || jobStatus
}

export enum JobStatus {
  PENDING = 'PENDING',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  PAST = 'PAST',
}

export enum BookingTypes {
  INSTANT = 'INSTANT_BOOK',
  STANDARD = 'STANDARD_BOOK',
  OFFLINE = 'OFFLINE',
  SUBMIT_BID = 'SUBMIT_BID',
  EXTERNAL_BOOK = 'EXTERNAL_BOOK',
  ALL = 'ALL',
}

export enum JobsFilter {
  JOB_LIST = 'JOB_LIST',
  PAST = 'PAST',
  CONTACTED_LOADS = 'CONTACTED_LOADS',
}

export enum DocumentType {
  BILL_OF_LADING = 'BILL_OF_LADING',
  RATE_CONFIRMATION = 'RATE_CONFIRMATION',
  LUMPER_RECEIPT = 'LUMPER_RECEIPT',
  OTHER = 'OTHER',
}

export const JOB_STATUS_DICT = {
  PENDING: [
    JobStatusDetail.BIDDING_REQUIRED,
    JobStatusDetail.ACTIVE_NEGOTIATION,
    JobStatusDetail.BOOKED_OFFLINE,
    JobStatusDetail.BROKER_SETUP_REQUIRED,
    JobStatusDetail.AWAITING_RATE_CONFIRMATION,
    JobStatusDetail.REVIEWING_RATE_CONFIRMATION,
  ],
  FUTURE: [JobStatusDetail.JOB_APPROVED, JobStatusDetail.NF_BOOKED],
  CURRENT: [
    JobStatusDetail.PENDING_CANCELLATION,
    JobStatusDetail.IN_PROGRESS,
    JobStatusDetail.PENDING_DELIVERY_VERIFICATION,
    JobStatusDetail.DELIVERY_VERIFICATION_ACCEPTED,
    JobStatusDetail.PENDING_PAYMENT_VERIFICATION,
    JobStatusDetail.PAYMENT_REQUIRED,
    JobStatusDetail.DELIVERY_VERIFICATION_REJECTED,
    JobStatusDetail.REQUESTED_CANCELLATION,
  ],
  PAST_VC: [JobStatusDetail.PAYMENT_ISSUED, JobStatusDetail.CANCELED, JobStatusDetail.ABORTED],
  PAST: [
    JobStatusDetail.PAYMENT_ISSUED,
    JobStatusDetail.CANCELED,
    JobStatusDetail.ABORTED,
    JobStatusDetail.NF_COMPLETED,
    JobStatusDetail.NF_REMOVED,
  ],
}

export type JOB_STATUS_DICT_KEYS = keyof typeof JOB_STATUS_DICT

export function getDefaultJobStatusDetailValue(): JobStatusDetail[] {
  return [...JOB_STATUS_DICT.CURRENT, ...JOB_STATUS_DICT.FUTURE, ...JOB_STATUS_DICT.PENDING]
}

// API

export const JOBS_SPLIT_LIST_PAGE_SIZE = 10

export interface GetJobsOptions {
  assignedTo?: string
  statusDetail?: JobStatusDetail[]
  orderBy?: string
  pageSize?: number
}

export function getJobs(page: string, options: GetJobsOptions = {}): Promise<PaginatedResults<Job>> {
  const { statusDetail, ...restOpions } = options
  const params = queryString.stringify(
    toSnakeCase({
      page,
      updated_status_details: true,
      ...(statusDetail ? { statusDetail: statusDetail.join(',') } : undefined),
      ...restOpions,
    })
  )

  return axios.get(`/api/v3/jobs/?${params}`).then((response) => {
    return {
      ...response.data,
      results: response.data.results.map((r: any) => {
        const job = toCamelCase(r)
        return {
          ...job,
          load: parseLoadDetails(job.load),
        }
      }),
    }
  })
}

export function getJobByHumanId(humanId: string): Promise<Job> {
  return axios.get(`/api/v3/jobs/retrieve-by-human-id/?human_id=${humanId}`).then((response) => {
    const job = toCamelCase(response.data)
    return {
      ...job,
      load: parseLoadDetails(job.load),
    } as Job
  })
}
