import { fetchAppSettings } from 'api'
import { AppSettings } from 'models'
import { ReduxDispatch } from '../../store/models'
import * as apiActions from '../apiStatus/actions'
import * as actionTypes from './actionTypes'

const saveSettings = (settings: AppSettings) => ({
  type: actionTypes.SAVE_SETTINGS,
  settings,
})

export const getSettings = () => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return fetchAppSettings()
    .then((settings) => {
      dispatch(apiActions.endApiCall())
      if (!!settings) {
        dispatch(saveSettings(settings))
      }
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

export const setGoogleApisLoaded = () => ({
  type: actionTypes.GOOGLE_APIS_LOADED,
})
