import { getBackgroundCheckStatus } from 'shared/models/backgroundCheck'

import { BackgroundCheckResult, CreateUserInfo, Location, User } from 'models'
import { ActivateUserPayload, CreateUserPayload, UpdateUserPayload } from 'models/payloads'
import {
  activateUser,
  confirmToken,
  createUser,
  getCurrentUser,
  ListUser,
  sendInvite,
  signUp,
  updateUserInfo,
  uploadCDL,
} from 'models/user'
import { ReduxDispatch } from '../../store/models'
import * as apiActions from '../apiStatus/actions'
import { userAuthenticationSuccess } from '../auth/authActions'
import { saveTeamInfo } from '../teams/actions'
import * as actionTypes from './actionTypes'

// TODO deprecate // check if we can move to setUserInfo
export const setCurrentUserInfo = (user: User | {}) => ({
  type: actionTypes.SET_CURRENT_USER_INFO,
  user,
})

export const resetCurrentUserInfo = () => ({
  type: actionTypes.RESET_CURRENT_USER_INFO,
})

export const setUserInfo = (user: User) => ({
  type: actionTypes.SET_USER_INFO,
  user,
})

export const addTeamMember = (newMember: User) => ({
  type: actionTypes.ADD_TEAM_MEMBER,
  newMember,
})

const userInviteSuccess = (invitedUser: User) => ({
  type: actionTypes.USER_INVITE_SUCCESS,
  invitedUser,
})

const saveBackgroundCheckStatusCurrentUser = (data: BackgroundCheckResult) => ({
  type: actionTypes.SAVE_BACKGROUND_CHECK_STATUS_CURRENT_USER,
  status: data.status,
})

const saveBackgroundCheckStatusDriver = (data: BackgroundCheckResult, userId: string) => ({
  type: actionTypes.SAVE_BACKGROUND_CHECK_STATUS_DRIVER,
  userId,
  status: data.status,
})

export const saveUserLocation = (location: Location) => ({
  type: actionTypes.SAVE_USER_LOCATION,
  location,
})

const cdlUploadSuccess = () => ({
  type: actionTypes.CDL_UPLOAD_SUCCESS,
})

export const getUserAndTeammateInfo = () => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return getCurrentUser()
    .then((data) => {
      const { team_object, ...user } = data
      dispatch(setCurrentUserInfo(user))
      dispatch(saveTeamInfo(team_object))
      dispatch(apiActions.endApiCall())
      return data
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

export const createUserAccount = (user: CreateUserInfo) => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return signUp(user)
    .then((result) => {
      let user: Partial<User> = {
        id: '',
        first_name: '',
        last_name: '',
        shipping_address: {
          id: '',
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: 'US',
          zip: '',
        },
      }
      Object.assign(user, result)
      dispatch(setCurrentUserInfo(user))
    })
    .catch((error) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

export const updateUser =
  (user_id: string, payload: UpdateUserPayload, spinner = true) =>
  (dispatch: ReduxDispatch) => {
    if (spinner) dispatch(apiActions.beginApiCall())
    return updateUserInfo(user_id, payload)
      .then((user: User) => {
        if (spinner) dispatch(apiActions.endApiCall())
        if (!!user) {
          dispatch(setCurrentUserInfo(user))
        }
      })
      .catch((error: any) => {
        if (spinner) dispatch(apiActions.endApiCall())
        throw error
      })
  }

export const updateDriver =
  (user_id: string, payload: UpdateUserPayload, resendInvite: boolean, updateCurrentUser?: boolean) =>
  (dispatch: ReduxDispatch) => {
    return updateUserInfo(user_id, payload).then((user: User) => {
      if (!!user) {
        dispatch(setUserInfo(user))
        if (resendInvite) {
          return dispatch(sendUserInvite(user))
        }
        if (updateCurrentUser) {
          dispatch(setCurrentUserInfo(user))
        }
      }
    })
  }

// similar to update user but without spinner
// and updating current user
export const editInfoWithoutSpinner =
  (user_id: string, payload: UpdateUserPayload) => (dispatch: ReduxDispatch) => {
    return updateUserInfo(user_id, payload).then((user: User) => {
      dispatch(setUserInfo(user))
      return user
    })
  }

export const confirmInvite = (token: string) => (dispatch: ReduxDispatch) => {
  let payload: ActivateUserPayload = { token }
  dispatch(apiActions.beginApiCall())
  return activateUser(payload)
    .then(() => {
      dispatch(apiActions.endApiCall())
      dispatch(userAuthenticationSuccess())
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

// TODO refactor invite driver to be generic invite user
export const inviteUser =
  (payload: CreateUserPayload, cdlPayload?: FormData) => (dispatch: ReduxDispatch) => {
    dispatch(apiActions.beginApiCall())
    return createUser(payload)
      .then((user) => {
        dispatch(apiActions.endApiCall())
        if (!!user) {
          if (cdlPayload) {
            dispatch(uploadCdlAction(user.id, cdlPayload))
          }
          dispatch(addTeamMember(user))
          return dispatch(sendUserInvite(user))
        }
      })
      .catch((error: any) => {
        dispatch(apiActions.endApiCall())
        throw error
      })
  }

export const sendUserInvite = (user: User) => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return sendInvite(user)
    .then(() => {
      dispatch(apiActions.endApiCall())
      dispatch(userInviteSuccess(user))
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

export const getBackgroundCheckResult =
  (userId: string, isCurrentUser: boolean) => (dispatch: ReduxDispatch) => {
    return getBackgroundCheckStatus(userId).then((data) => {
      if (isCurrentUser) {
        dispatch(saveBackgroundCheckStatusCurrentUser(data))
      }
      //if is current user to have consistent data should update currentUser and teamMember
      dispatch(saveBackgroundCheckStatusDriver(data, userId))
    })
  }

export const fetchTeamMembersBackgroundCheck =
  (teamMembers: ListUser[], isAdmin: boolean, fetchOnlyMissing = false) =>
  (dispatch: ReduxDispatch) => {
    if (isAdmin) {
      teamMembers.forEach((member) => {
        if (fetchOnlyMissing && member.background_check_status) return
        dispatch(getBackgroundCheckResult(member.id, false))
      })
    }
  }

export const confirmMobileToken = (token: string) => (dispatch: ReduxDispatch) => {
  // TODO consider consolidating with confirmInvite
  dispatch(apiActions.beginApiCall())
  return confirmToken(token)
    .then(() => {
      dispatch(apiActions.endApiCall())
      dispatch(userAuthenticationSuccess())
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

export const uploadCdlAction = (userId: string, payload: FormData) => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return uploadCDL(userId, payload)
    .then(() => {
      dispatch(apiActions.endApiCall())
      return dispatch(cdlUploadSuccess())
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}
