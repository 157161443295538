import { useSelector } from 'react-redux'

import { currentTeamSelector } from '../../../redux/selectors/team'
import { ApplicationState } from '../../../redux/store/models'

export const useShowSideBar = () => {
  const isAuthenticated = useSelector((state: ApplicationState) => state.auth.isAuthenticated)
  const team = useSelector(currentTeamSelector)

  if (!team.is_approved) return false
  if (!isAuthenticated) return false
  return true
}
