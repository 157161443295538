import { useDispatch } from 'react-redux'
import { showInfoBanner } from 'shared/actions/ui/actions'

export function useInfoMessage() {
  const dispatch = useDispatch()

  return function infoMessage({
    message,
    title,
  }: {
    message: string | string[] | JSX.Element[] | JSX.Element
    title?: string
  }) {
    dispatch(showInfoBanner({ message, title }))
  }
}
