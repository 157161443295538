import { createTeam, updateTeamInfo, updateTeamTrailerCounts } from 'api/teams'
import { Team } from 'models'
import { UpdateTrailerCountPayload } from 'models/payloads'
import { ReduxDispatch } from '../../store/models'
import * as apiActions from '../apiStatus/actions'
import * as actionTypes from './actionTypes'

export const saveTeamInfo = (team: Team) => ({
  type: actionTypes.SAVE_TEAM_INFO,
  team,
})

export const createTeamInfo = (team: Team) => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return createTeam(team)
    .then((result: any) => {
      dispatch(apiActions.endApiCall())
      if (Boolean(result.data)) {
        let newTeam = {
          num_active_drivers: 0,
          billing_address: {
            id: '',
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: 'US',
            zip: '',
          },
        }
        const team: Team = Object.assign(newTeam, result.data)
        dispatch(saveTeamInfo(team))
        return newTeam
      }
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}

export const updateTeam = (team: Team) => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return updateTeamInfo(team)
    .then((result: Team) => {
      dispatch(apiActions.endApiCall())
      dispatch(saveTeamInfo(result))
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error.data
    })
}

export const updateTrailerCounts = (payload: UpdateTrailerCountPayload) => (dispatch: ReduxDispatch) => {
  dispatch(apiActions.beginApiCall())
  return updateTeamTrailerCounts(payload)
    .then((team: Team) => {
      dispatch(apiActions.endApiCall())
      if (!!team) {
        dispatch(saveTeamInfo(team))
      }
    })
    .catch((error: any) => {
      dispatch(apiActions.endApiCall())
      throw error
    })
}
